import React from 'react';
import SEO from '../components/seo';
import Layout from '../components/layout';

import SignUpHero from '../components/image-components/hero/signUpHero';
import PaymentOnly from '../components/image-components/payment/paymentOnly';

const Payment = () => (
  <>
    <SEO title="Payment" />
    <Layout>
      <SignUpHero title="PAYMENT" />
      <PaymentOnly />
    </Layout>
  </>
);
export default Payment;
