import React, { useState } from 'react';
import axios from 'axios';
import { loadStripe } from '@stripe/stripe-js';
import { Row, Col, Card, Spin, notification } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import PaymentVocation from './paymentVocation';

const PaymentOnly = () => {
  const [vocationLoading, setVocationLoading] = useState(false);

  const redirectToCheckout = async (course) => {
    const courseName = course.split(' ')[0];
    const stripePromise = loadStripe(process.env.STRIPE_API_KEY);
    const stripe = await stripePromise;

    // Nested trycatch to retry for initial Stripe 400
    try {
      // call netlify function for purchase
      console.log('first');
      const { data } = await axios({
        url: `${process.env.LAMBDA_ENDPOINT}/purchase?course=${courseName}`,
        method: 'POST',
      });
      const result = await stripe.redirectToCheckout({
        sessionId: data.id,
      });
    } catch (error) {
      try {
        console.log('first');
        const { data } = await axios({
          url: `${process.env.LAMBDA_ENDPOINT}/purchase?course=${courseName}`,
          method: 'POST',
        });
        const result = await stripe.redirectToCheckout({
          sessionId: data.id,
        });
      } catch (error) {
        console.error(error.errorMessage);
        notification.open({
          message: 'Network connection error.',
          description:
            'Please refresh and try submitting your payment again. Thank you!',
          icon: (
            <ExclamationCircleOutlined
              style={{
                color: 'red',
              }}
            />
          ),
        });
      }
    }
  };

  return (
    <div style={{ padding: '1rem 3rem' }}>
      <Row gutter={[40, 24]} justify="center">
        <Col lg={6} md={11} sm={18}>
          <Spin spinning={vocationLoading}>
            <Card
              hoverable
              cover={<PaymentVocation />}
              onClick={() => {
                setVocationLoading(true);
                redirectToCheckout('Vocation Advance');
              }}
            >
              <Card.Meta
                title="Vocation Advance"
                description="Loving Our Vocations via the Love of God."
              />
            </Card>
          </Spin>
        </Col>
      </Row>
    </div>
  );
};

export default PaymentOnly;
